import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { take, mergeMap, map, catchError } from 'rxjs/operators';
import { AuthActions } from './auth.actions';
import { IAuthStateModel } from './auth.state.models';

@Injectable({ providedIn: 'root' })
export class AccountsLoadedGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    // Check if the accessToken or signuptoken is present in session storage
    const accessToken = sessionStorage.getItem('accessToken');
    
    if (!accessToken) {
      // If accessToken is missing, redirect to login
      this.router.navigate(['/login']);
      return of(false);
    }

    // Proceed to check if accounts are loaded
    return this.store
      .select((state: { auth: IAuthStateModel }) => ({
        loaded: state.auth.allAccounts.length > 0,
        userId: state.auth.id,
      }))
      .pipe(
        take(1),
        mergeMap(({ loaded, userId }) => {
          if (loaded) {
            return of(true); // If accounts are already loaded, allow navigation.
          }

          // Dispatch LoadUserAccounts action if accounts are not loaded.
          return this.store.dispatch(new AuthActions.LoadUserAccounts()).pipe(
            map(() => true), // Allow navigation after accounts are loaded.
            catchError(() => {
              this.router.navigate(['/login']); // Redirect to login on failure.
              return of(false);
            })
          );
        })
      );
  }
}
