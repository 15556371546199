export const TOKEN = 'accessToken';
export const LOCAL_STORAGE_PROFILE_ID = 'profile_id';
export const LOCAL_STORAGE_ACTIVE_ACCOUNT_ID = 'active_account_id';
export const SIGNUP_TOKEN = 'signupToken';
export const FILE_BASE_URL: string =
  'https://mango-public-storage-dev.s3.eu-central-1.amazonaws.com';

export const GENDER = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
];

export const MARITAL_STATUS = [
  { value: 'single', label: 'Single' },
  { value: 'married', label: 'Married' },
  { value: 'divorced', label: 'Divorced' },
  { value: 'separated', label: 'Separated' },
];

export const ANNUAL_INCOME = [
  { value: 'Below ₦5,000,000', label: 'Below ₦ 5,000,000' },
  { value: '₦5,000,000 - ₦19,999,999', label: '₦ 5,000,000 - ₦ 19,999,999' },
  { value: '₦20,000,000 - ₦50,000,000', label: '₦ 20,000,000 - ₦ 50,000,000' },
  { value: 'above ₦50,000,000', label: 'Above ₦ 50,000,000' },
];

export const FUND_SOURCE = [
  { value: 'business', label: 'Business' },
  { value: 'salary', label: 'Salary' },
  { value: 'inheritance', label: 'Inheritance' },
  { value: 'investments', label: 'Investments' },
  { value: 'pension', label: 'Pension' },
  { value: 'parents', label: 'Parents' },
  { value: 'others', label: 'Others' },
]

export const EMPLOYMENT_STATUS = [
  { value: 'employed', label: 'Employed' },
  { value: 'unemployed', label: 'Unemployed' },
  { value: 'self-employed', label: 'Self Employed' },
  { value: 'retired', label: 'Retired' },
  { value: 'student', label: 'Student' },
  { value: 'others', label: 'Others' },
];


export const NIGERIAN_TITLES = {
  MR: 'Mr.',
  MRS: 'Mrs.',
  MISS: 'Miss.',
  MS: 'Ms.',
  DR: 'Dr.',
  PROF: 'Prof.',
  ENGR: 'Engr.',
  BARR: 'Barr.',
  REV: 'Rev.',
  HON: 'Hon.',
  PASTOR: 'Pastor.',
  ARCH: 'Arch.',
  CHIEF: 'Chief.',
  OBA: 'Oba.',
  OLU: 'Olu..',
  EMIR: 'Emir',
  EZE: 'Eze.',
  OBI: 'Obi.',
  ONYEKA: 'Onyeka.',
  OONI: 'Ooni.',
  SARKI: 'Sarki.',
  OLORI: 'Olori.',
  ALHAJI: 'Alhaji.',
  ALHAJA: 'Alhaja.',
  DAME: 'Dame.',
  SIR: 'Sir.',
  LADY: 'Lady.',
  PRINCE: 'Prince.',
  PRINCESS: 'Princess.',
};

export const KIN_RELATIONSHIPS = [
  { value: 'child', label: 'Child' },
  { value: 'parent', label: 'Parent' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'husband', label: 'Husband' },
  { value: 'wife', label: 'Wife' },
  { value: 'others', label: 'Others' },
];

export const MEANS_OF_ID = [
  { value: 'license', label: "Drivers License" },
  { value: 'passport', label: 'International Passport' },
  { value: 'card', label: "Voters Card" },
  { value: 'nin', label: 'Nin' },
];


export const CURRENCY = [
  { value: 'NGN', label: 'NGN' },
  { value: 'USD', label: 'USD' },
];


export const SIGNATORY_CLASS = [
  { value: 'Class A', label: 'Class A' },
  { value: 'Class B', label: 'Class B' },
  { value: 'Class C', label: 'Class C' },
];


export interface IFILTER {
  filterKey: string;
  label: string;
  type: 'text' | 'number' | 'date' | 'dropdown';
  dropdown?: { key: string; value: string }[];
}