import { Injectable, NgZone, inject } from '@angular/core';
import { AuthService } from '@app/core/services/client/auth.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SIGNUP_TOKEN, TOKEN } from '@app/core/constants/app.constant';
import { AccountService } from '@app/core/services/client/account.service';
import { IAuthStateModel, IAccountStateModel } from './auth.state.models';



@State<IAuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    id: null,
    isAccountSetup: null,
    loading: null,
    success: false,
    error: null,
    account_id: null,
    activeAccount: null,
    allAccounts: [],
  },
})
@Injectable()
export class AuthState {
  subs: Subscription[] = [];
  authService = inject(AuthService);
  accountService = inject(AccountService);
  store = inject(Store);
  router = inject(Router);
  private ngZone = inject(NgZone);


  // Helper method to fetch and format accounts
  private async fetchAndFormatAccounts(userId: string) {
    try {
      const accountList = await firstValueFrom(
        this.accountService.getAccountsAttachedToUsers({ user_identifier: userId })
      );

      const accounts = accountList.data;

      if (accounts.length === 0) {
        this.ngZone.run(() => this.router.navigateByUrl('/create-account/type'));
        return null;
      }

      const accountsWithTier = await Promise.all(
        accounts.map(async account => {
          const tierResponse = await firstValueFrom(
            this.accountService.getAccountKycTier(account.id)
          );
          return {
            ...account,
            tier: tierResponse?.data?.kyc_levels || null,
          };
        })
      );

      return accountsWithTier.map(account => ({
        account_id: account.id,
        account_type: account.account_type,
        tier: account.tier,
        name:
          account.account_type === 'individual'
            ? `${account.user_profile?.first_name || ''} ${account.user_profile?.last_name || ''}`.trim()
            : account.corporate_kyc?.name || '-',
        mango_account_number: account.mango_account_number,
        virtual_account_number: account.virtual_account_number,
        virtual_account_name: account.virtual_account_name,
        admin_user_id: account.admin_user_id,
        kyc_status: account.kyc_status,
        wallet: account.wallet,
        corporate_kyc: account.corporate_kyc,
        user_profile: account.user_profile,
        is_blocked: account.is_blocked,
        accepted_lcm_investment_agreement: account.accepted_lcm_investment_agreement,
        accepted_pms_investment_agreement: account.accepted_pms_investment_agreement

      }));
    } catch (error) {
      console.error('Error fetching accounts:', error);
      throw error;
    }
  }


  @Action(AuthActions.Login)
  async login(ctx: StateContext<IAuthStateModel>, action: AuthActions.Login) {
    ctx.patchState({ loading: true });

    try {
      const response = await firstValueFrom(this.authService.login(action.payload));
      sessionStorage.setItem(TOKEN, response.token);

      // Cache profile_id
      localStorage.setItem('profile_id', response.id);

      ctx.patchState({
        token: response.token,
        id: response.id,
        isAccountSetup: response.is_account_setup,
        success: true,
        error: null,
      });

      const formattedAccounts = await this.fetchAndFormatAccounts(response.id);

      if (formattedAccounts) {
        const activeAccount = formattedAccounts[0];
        localStorage.setItem('active_account_id', activeAccount.account_id);

        ctx.dispatch(
          new AuthActions.LoadUserAccountsSuccess(activeAccount, formattedAccounts)
        );

        this.ngZone.run(() => this.router.navigateByUrl('dashboard'));
      }
    } catch (error: any) {
      if (error.status === 409) {
        localStorage.setItem(SIGNUP_TOKEN, error.error.token);
        this.ngZone.run(() => this.router.navigateByUrl('/create-account/verification'));
      } else {
        ctx.patchState({ loading: false, error: error.message || 'Login failed' });
        ctx.dispatch(new AuthActions.LoadUserAccountsFailure(error));
      }
    } finally {
      ctx.patchState({ loading: false });
    }
  }


  @Action(AuthActions.LoadUserAccounts)
  async loadUserAccounts(ctx: StateContext<IAuthStateModel>) {
    const state = ctx.getState();

    // Retrieve cached profile_id and active_account_id from localStorage
    const profile_id = localStorage.getItem('profile_id');
    const active_account_id = localStorage.getItem('active_account_id');

    if (!profile_id) {
      throw new Error('User profile_id not found in storage');
    }

    try {
      const formattedAccounts = await this.fetchAndFormatAccounts(profile_id);

      if (!formattedAccounts) {
        throw new Error('No accounts found for user');
      }

      const activeAccount = formattedAccounts.find(account => account.account_id === active_account_id) || formattedAccounts[0];

      // Update state with all accounts and active account
      ctx.patchState({
        allAccounts: formattedAccounts,
        activeAccount: activeAccount,
        account_id: activeAccount?.account_id || null,
        id: profile_id,
      });
    } catch (error) {
      console.error('Error loading user accounts:', error);
      // ctx.patchState({ allAccounts: [], activeAccount: null });
    }
  }


  @Action(AuthActions.SetAccountID)
  createAccount(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetAccountID) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      account_id: action.account_id,
    });
  }


  @Action(AuthActions.LoadUserAccountsSuccess)
  loadUserAccountsSuccess(ctx: StateContext<IAuthStateModel>, action: AuthActions.LoadUserAccountsSuccess) {
    ctx.patchState({
      activeAccount: action.activeAccount,
      allAccounts: action.allAccounts,
    });
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoading(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetLoadingStatus) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loading: action.payload,
    });
  }

  @Action(AuthActions.SetActiveAccount)
  setActiveAccount(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetActiveAccount) {
    const state = ctx.getState();
    const updatedActiveAccount = {
      account_id: action.account_id,
      account_type: action.account_type,
      tier: action.tier,
      name: action.name,
      mango_account_number: action.mango_account_number,
      virtual_account_number: action.virtual_account_number,
      virtual_account_name: action.virtual_account_name,
      admin_user_id: action.admin_user_id,
      kyc_status: action.kyc_status,
      wallet: action.wallet,
      corporate_kyc: action.corporate_kyc,
      user_profile: action.user_profile,
      is_blocked: action.is_blocked,
      accepted_lcm_investment_agreement: action.accepted_lcm_investment_agreement,
      accepted_pms_investment_agreement: action.accepted_pms_investment_agreement
    };

    ctx.setState({
      ...state,
      activeAccount: updatedActiveAccount,
    });

    // Cache only active account_id to localStorage
    localStorage.setItem('active_account_id', action.account_id);
  }


  @Action(AuthActions.SetAllAccounts)
  setAllAccounts(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetAllAccounts) {
    const state = ctx.getState();
    const formattedAccounts = action.accounts.map(account => ({
      account_id: account.id,
      account_type: account.account_type,
      tier: account.tier || null,
      name:
        account.account_type === 'individual'
          ? `${account.user_profile?.first_name || ''} ${account.user_profile?.last_name || ''
            }`.trim()
          : account.corporate_kyc?.name || '-',
      mango_account_number: account.mango_account_number,
      virtual_account_number: account.virtual_account_number,
      virtual_account_name: account.virtual_account_name,
      admin_user_id: account.admin_user_id,
      kyc_status: account.kyc_status,
      wallet: account.wallet,
      corporate_kyc: account.corporate_kyc,
      user_profile: account.user_profile,
      is_blocked: account.is_blocked,
      accepted_lcm_investment_agreement: account.accepted_lcm_investment_agreement,
      accepted_pms_investment_agreement: account.accepted_pms_investment_agreement

    }));

    ctx.setState({
      ...state,
      allAccounts: formattedAccounts,
    });


  }

  @Action(AuthActions.Signup)
  signup(ctx: StateContext<IAuthStateModel>, action: AuthActions.Signup) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      id: action.payload.userId,
      token: action.payload.token,
      // isAccountSetup: true,
      loading: false,
      success: true,
      error: null,
    });
  }
}
